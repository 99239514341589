import type { FC } from 'react';
import { LayeredBox } from '~src/components/box/layered';
import { Dialog } from '~src/components/dialog';
import { Spacer } from '~src/components/layout';
import { Portal } from '~src/components/portal';
import { Box } from '~src/styled-system/jsx';
import { CheckoutActions } from '../components/action';
import { CheckoutBody } from '../components/body';
import { CheckoutPricingDetails } from '../components/details';
import { CheckoutHeader } from '../components/header';
import { useCheckoutContext } from '../providers/Checkout';

export const CheckoutSettlementDialog: FC = () => {
  const { isModalOpen, setModalOpen, stateInflow } = useCheckoutContext();
  return (
    <Dialog.Root
      lazyMount
      unmountOnExit
      open={isModalOpen && stateInflow === 'checkout'}
      onOpenChange={(e) => setModalOpen(e.open)}
      closeOnInteractOutside={false}
    >
      <Portal>
        <Dialog.Backdrop />
        <Dialog.Positioner>
          <Dialog.Content>
            <Dialog.LayeredBoxGroup>
              <LayeredBox p={'innerBox'}>
                <CheckoutHeader />
                <Spacer.Box />
                <Box
                  overflow={'scroll'}
                  maxHeight={{
                    base: '[60vh]',
                    md: '[50vh]',
                  }}
                >
                  <CheckoutBody />
                </Box>
              </LayeredBox>
              <LayeredBox
                p={'innerBox'}
                // pos={'sticky'}
                bg={'white'}
                // bottom={'0'}
                // left={'0'}
                // right={'0'}
              >
                <CheckoutPricingDetails />
                <Spacer.Box />
                <CheckoutActions />
              </LayeredBox>
            </Dialog.LayeredBoxGroup>
          </Dialog.Content>
        </Dialog.Positioner>
      </Portal>
    </Dialog.Root>
  );
};
