import type { FC } from 'react';
import { LayeredThinBoxGroup } from '~src/components/box/layered';
import { useCheckoutContext } from '../providers/Checkout';
import { CheckoutItemRow } from './item';

export const CheckoutBody: FC = () => {
  const { checkoutItems } = useCheckoutContext();
  return (
    <LayeredThinBoxGroup>
      {checkoutItems?.map((c) => {
        return <CheckoutItemRow key={`checkout-item-${c.objId}`} item={c} />;
      })}
    </LayeredThinBoxGroup>
  );
};
