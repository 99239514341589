import { useMemo, type FC } from 'react';
import { LayeredBox } from '~src/components/box/layered';
import { Spacer } from '~src/components/layout';
import { DisplayP, H4 } from '~src/components/text';
import { StatefulPill } from '~src/components/text/pill';
import { useCheckoutReceiptContext } from '~src/shared-apps/receipt/providers/CheckoutReceipt';
import { Box, Flex } from '~src/styled-system/jsx';
import type { StatefulColorType } from '~src/theme/variants/stateful';

export const CheckoutReceiptHeader: FC = () => {
  const { transactionStatus } = useCheckoutReceiptContext();

  const colorState = useMemo((): StatefulColorType => {
    if (transactionStatus === 'error') {
      return 'error';
    }
    if (transactionStatus === 'success') {
      return 'success';
    }
    // if (transactionStatus === TransactionStatus.REPRICED) {
    //   return 'warning';
    // }
    return 'neutral';
  }, [transactionStatus]);

  const statefulPillText = useMemo(() => {
    switch (transactionStatus) {
      case null:
        return 'Unsupported';
      case 'error':
        return 'Failed';
      case 'success':
        return 'Success';
      default:
        return 'Pending';
    }
  }, [transactionStatus]);

  const statefulTitle = useMemo(() => {
    switch (transactionStatus) {
      case null:
        return 'Unsupported Txn';
      case 'error':
        return 'Minting Failed';
      case 'success':
        return 'Mint Successful';
      default:
        return 'Minting...';
    }
  }, [transactionStatus]);

  return (
    <>
      <LayeredBox
        // bg={'white'}
        // rounded={'innerBox'}
        // border={'1px solid'}
        // borderColor={'gray.100'}
        // overflow={'hidden'}
        p={'zero'}
        bg={'transparent'}
      >
        <Flex
          // p={'innerBox'}
          flx={'flexRowEnds'}
        >
          <Box>
            <DisplayP.Caption color={'gray.400'}>
              Checkout Receipt
            </DisplayP.Caption>
            <Spacer.QuarterIncrement />
            <H4
              lineHeight={'singleLine'}
              fontWeight={'display.bold'}
              color={'black'}
            >
              {statefulTitle}
            </H4>
          </Box>
          <StatefulPill
            isLoading={transactionStatus === 'pending'}
            state={colorState}
            // rounded={'innerOfSquaredButton'}
          >
            {statefulPillText}
          </StatefulPill>
        </Flex>
        {/* {transactionStatus === 'success' && <RemoveMintedItemsCard/>}
      {transactionStatus === 'error' && <FailedCard />} */}
      </LayeredBox>
    </>
  );
};
