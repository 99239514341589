import type { FC } from 'react';
import { P, match } from 'ts-pattern';
import { HiraethCheckoutItem } from '~src/shared-apps/checkout/components/item/hiraeth/item';
import type { CheckoutItem } from '../../types';

export type CheckoutItemRowProps = {
  item: CheckoutItem;
};

export const CheckoutItemRow: FC<CheckoutItemRowProps> = ({ item }) => {
  return match(item)
    .with(
      {
        objId: ['hiraeth', P._, P._],
      },
      () => {
        return <HiraethCheckoutItem.Row item={item} />;
      },
    )
    .exhaustive();
};
