import { FC } from 'react';
import { SquareMinorButton } from '~src/components/button';
import { DisplayP, H5 } from '~src/components/text';
import { css } from '~src/styled-system/css';
import { Box, HStack } from '~src/styled-system/jsx';
import {
  statefulColorsVariant,
  statefulElementVariant,
  type StatefulColorType,
} from '~src/theme/variants/stateful';
import type { CheckoutErrorSource } from '../types/errors';

export type BaseCheckoutStateCardProps = {
  title: string;
  description: string;
  action?: () => void | Promise<void>;
  actionLabel?: string;
  state: StatefulColorType;
  source: CheckoutErrorSource;
  hideAction?: boolean;
  hideDescription?: boolean;
};

export const BaseCheckoutStateCard: FC<BaseCheckoutStateCardProps> = ({
  title,
  description,
  action,
  actionLabel,
  state,
  source,
  hideAction,
  hideDescription,
}) => {
  // const [color] = useMemo(() => getStatefulColorPair(state), [state]);
  // const statefulPillText = useMemo(() => {
  //   if (state === 'error') return 'Error';
  //   if (state === 'warning') return 'Warning';
  //   if (state === 'success') return 'Success';
  //   if (state === 'neutral') return 'Info';
  // }, [state]);
  const shouldShowAction = !hideAction && action;
  return (
    <Box
      p={'ten'}
      rounded={'squaredButton'}
      css={css.raw(statefulColorsVariant[state])}
    >
      <Box>
        <H5 color={'inherit'}>{title}</H5>
        {!hideDescription && (
          <DisplayP.Default lineHeight={'[1.2]'} color={'inherit'}>
            {description}
          </DisplayP.Default>
        )}
      </Box>
      {shouldShowAction && (
        <HStack gap={'increment'} mt={'increment'}>
          <SquareMinorButton
            onClick={action}
            css={css.raw(statefulElementVariant[state])}
          >
            {actionLabel ?? '-'}
          </SquareMinorButton>
        </HStack>
      )}
    </Box>
  );
};
