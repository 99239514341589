import type { Evm } from '@pob/shared';
import { useMemo, type FC } from 'react';
import { StatelessHiraethAddressRow } from '~src/pages/hiraeth/txn/components/body/txn/address';
import { HiraethChainRow } from '~src/pages/hiraeth/txn/components/body/txn/chain';
import { StatelessHiraethTxnHashRow } from '~src/pages/hiraeth/txn/components/body/txn/hash';
import { TableDetailsBox } from '~src/pages/hiraeth/txn/components/common';
import { useCheckoutReceiptContext } from '~src/shared-apps/receipt/providers/CheckoutReceipt';
import { uniq } from '~src/utils/lodash';

export const TxnDetailsCard: FC = () => {
  const { txnHash, transactionReceiptRes, receiptItems } =
    useCheckoutReceiptContext();

  const mintedTo = useMemo(() => {
    return uniq(
      receiptItems?.reduce((acc, item) => {
        if (item.state.type !== 'minted') return acc;
        return [...acc, item.state.owner];
      }, [] as Evm.Address[]),
    );
  }, [receiptItems]);

  return (
    <TableDetailsBox>
      <StatelessHiraethTxnHashRow txnHash={txnHash} />
      <HiraethChainRow />
      {mintedTo?.map((to) => (
        <StatelessHiraethAddressRow
          key={`minted-to-${to}`}
          address={to}
          label="Minted to"
        />
      ))}
    </TableDetailsBox>
  );
};
