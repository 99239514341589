import { FC } from 'react';
import { match } from 'ts-pattern';
import { capitalize } from '~src/utils/lodash';
import type { CheckoutErrorType, CheckoutErrorWithType } from '../types/errors';
import { BaseCheckoutStateCard } from './stateCard';

export type CheckoutErrorProps = {
  error: CheckoutErrorWithType<CheckoutErrorType>;
  hideDescription?: boolean;
};

export const CheckoutError: FC<CheckoutErrorProps> = ({
  error,
  hideDescription,
}) => {
  return match(error)
    .with({ type: 'incorrect-network' }, (e) => (
      <BaseCheckoutStateCard
        title={'Incorrect Network'}
        state={'neutral'}
        source={e.source}
        description={`You are currently on the wrong network to mint.`}
        action={e.action}
        actionLabel={e.actionLabel}
        hideAction={e.hideActionOnMint}
        hideDescription={hideDescription}
      />
    ))
    .with({ type: 'insufficient-funds' }, (e) => (
      <BaseCheckoutStateCard
        title={'Insufficient Balance'}
        state={'warning'}
        source={e.source}
        description={`You do not have enough funds to mint. Please deposit more funds to your wallet.`}
        action={e.action}
        actionLabel={e.actionLabel}
        hideAction={e.hideActionOnMint}
        hideDescription={hideDescription}
      />
    ))
    .with({ type: 'exceeds-supply' }, (e) => (
      <BaseCheckoutStateCard
        title={'Exceeds Supply'}
        state={'error'}
        source={e.source}
        description={`You are minting more than the remaining supply. Please reduce the number of items you are minting.`}
        action={e.action}
        actionLabel={e.actionLabel}
        hideAction={e.hideActionOnMint}
        hideDescription={hideDescription}
      />
    ))
    .with({ type: 'sold-out' }, (e) => (
      <BaseCheckoutStateCard
        title={'Sold Out'}
        state={'neutral'}
        source={e.source}
        description={`${capitalize(e.source)} has sold out. Stay tuned for updates on the future of ${capitalize(e.source)}.`}
        action={e.action}
        actionLabel={e.actionLabel}
        hideAction={e.hideActionOnMint}
        hideDescription={hideDescription}
      />
    ))
    .with({ type: 'minted-in-cart' }, (e) => (
      <BaseCheckoutStateCard
        title={'Minted In Cart'}
        state={'error'}
        source={e.source}
        description={`There are ${capitalize(e.source)} items already minted in your cart. Please remove them before continuing.`}
        action={e.action}
        actionLabel={e.actionLabel}
        hideAction={e.hideActionOnMint}
        hideDescription={hideDescription}
      />
    ))
    .with({ type: 'minted' }, (e) => (
      <BaseCheckoutStateCard
        title={'Minted'}
        state={'error'}
        source={e.source}
        description={`Somebody else has minted your item.`}
        action={e.action}
        actionLabel={e.actionLabel}
        hideAction={e.hideActionOnMint}
        hideDescription={hideDescription}
      />
    ))
    .with({ type: 'unable-to-mint' }, (e) => (
      <BaseCheckoutStateCard
        title={'Unable To Mint'}
        state={'error'}
        source={e.source}
        description={`There was an issue preparing the mint transaction. Contact Pob Studio for help. Error Code: ${(e as CheckoutErrorWithType<'unable-to-mint'>).reason}`}
        action={e.action}
        actionLabel={e.actionLabel}
        hideAction={e.hideActionOnMint}
        hideDescription={hideDescription}
      />
    ))
    .with({ type: 'txn-failure', errorType: 'unknown' }, (e) => (
      <BaseCheckoutStateCard
        title={'Error Preparing Transaction'}
        state={'error'}
        source={e.source}
        description={`There was an issue preparing the mint transaction. Contact Pob Studio for help.`}
        action={e.action}
        actionLabel={e.actionLabel}
        hideAction={e.hideActionOnMint}
      />
    ))
    .with({ type: 'txn-failure', errorType: 'cancelled' }, (e) => (
      <BaseCheckoutStateCard
        title={'Transaction Cancelled'}
        state={'warning'}
        source={e.source}
        description={`You have cancelled the mint transaction in the wallet.`}
        action={e.action}
        actionLabel={e.actionLabel}
        hideAction={e.hideActionOnMint}
      />
    ))
    .with({ type: 'uninitialized' }, (e) => (
      <BaseCheckoutStateCard
        title={'Uninitialized'}
        state={'error'}
        source={e.source}
        description={`You should not see this error. Please contact Pob Studio for help.`}
        action={e.action}
        actionLabel={e.actionLabel}
        hideAction={e.hideActionOnMint}
      />
    ))
    .with({ type: 'linked-account-mismatch' }, (e) => (
      <BaseCheckoutStateCard
        title={'Connected Wallet Mismatch'}
        state={'neutral'}
        source={e.source}
        description={`Your connected wallet is not the same as the in-session user. You can mint, and to view your items, simply switch to the correct wallet in your settings.`}
        action={e.action}
        actionLabel={e.actionLabel}
        hideAction={e.hideActionOnMint}
      />
    ))
    .otherwise(() => {
      throw new Error('Invalid error type');
      return null;
    });
};
