import { LayeredThinBoxGroup } from '~src/components/box/layered';
import { Box } from '~src/styled-system/jsx';
import { useCheckoutReceiptContext } from '../../providers/CheckoutReceipt';
import { CheckoutReceiptItemRow } from '../item';

export const CheckoutReceiptBody = () => {
  const { txnHash, receiptItems } = useCheckoutReceiptContext();

  return (
    <Box>
      {/* <DetailsLabel label="Transaction" /> */}
      {!!receiptItems?.length && (
        <LayeredThinBoxGroup
          border={'1px solid'}
          borderColor={'gray.200'}
          shadow={'soft'}
        >
          {receiptItems?.map((item) => (
            <CheckoutReceiptItemRow
              key={`${txnHash}-${item.objId}`}
              item={item}
            />
          ))}
        </LayeredThinBoxGroup>
      )}
    </Box>
  );
};
