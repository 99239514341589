import type { FC } from 'react';
import { Spinner } from 'react-activity';
import { LayeredBox } from '~src/components/box/layered';
import { Dialog } from '~src/components/dialog';
import { Spacer } from '~src/components/layout';
import { Portal } from '~src/components/portal';
import { useCheckoutContext } from '~src/shared-apps/checkout/providers/Checkout';
import { Box } from '~src/styled-system/jsx';
import { colors } from '~src/theme/tokens/colors';
import { CheckoutReceiptBody } from '../components/body';
import { CheckoutReceiptDismiss } from '../components/dismiss';
import { CheckoutReceiptFooter } from '../components/footer';
import { CheckoutReceiptHeader } from '../components/header';
import { CheckoutReceiptProvider } from '../providers/CheckoutReceipt';

export const CheckoutReceiptDialog: FC = () => {
  const { isModalOpen, setModalOpen, stateInflow, mintTxnHash} =
    useCheckoutContext();
  return (
    <Dialog.Root
      lazyMount
      unmountOnExit
      open={isModalOpen && stateInflow === 'receipt'}
      onOpenChange={(e) => setModalOpen(e.open)}
      closeOnInteractOutside={false}
    >
      <Portal>
        <Dialog.Backdrop />
        <Dialog.Positioner>
          <Dialog.Content>
            <Dialog.LayeredBoxGroup>
              {!mintTxnHash ? (
                <Box py={'twoExLargestBox'} flx={'flexColumnCenter'}>
                  <Spinner size={16} color={colors['gray.400']} />
                </Box>
              ) : (
                <CheckoutReceiptProvider
                  txnHash={mintTxnHash}
                >
                  <LayeredBox p={'innerBox'}>
                    <CheckoutReceiptHeader />
                    <Spacer.Box />
                    <CheckoutReceiptBody />
                  </LayeredBox>
                  <LayeredBox p={'innerBox'} bg={'white'}>
                    <CheckoutReceiptFooter />
                    <Spacer.InnerBox />
                    <CheckoutReceiptDismiss />
                  </LayeredBox>
                </CheckoutReceiptProvider>
              )}
            </Dialog.LayeredBoxGroup>
          </Dialog.Content>
        </Dialog.Positioner>
      </Portal>
    </Dialog.Root>
  );
};
