import type { SupportedBlockverseChainId } from '@pob/blockverse';
import type { Evm } from '@pob/shared';
import { NULL_BYTES } from '@pob/shared';
import type { AvailableChainId } from '@protocol/chains';
import { mintingMulticallAbi } from '@protocol/core';
import { QueryStatus, useQueryClient } from '@tanstack/react-query';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { decodeFunctionData } from 'viem/utils';
import {
  useTransaction,
  useWaitForTransactionReceipt,
  type Config,
  type UseWaitForTransactionReceiptReturnType,
} from 'wagmi';
import {
  CHECKOUT_PROJECT_METADATA,
  SupportedCheckoutProjectId,
} from '~src/config/checkout';
import { isSupportedChainForProjectForCheckout } from '~src/config/utils/isSupportedChainForProjectForCheckout';
import { useHiraethObjId } from '~src/pages/hiraeth/txn/hooks/useHiraethObjId';
import {
  usePreferredChain,
  usePreferredDeployment,
} from '~src/providers/PreferredNetwork';
import { useCart, type CartItem } from '~src/shared-apps/cart/stores';
import { getTransientCheckoutItemStateQueryKey } from '~src/shared-apps/checkout/query';
import { useAddSingleBlockverseContext } from '~src/shared-apps/context/hooks/useAddSingleBlockverseContext';
import { isEqAddress } from '~src/utils/bytes/isEqAddress';
import { find } from '~src/utils/lodash';
import { serializeId } from '~src/utils/strings/serializeId';
import {
  CONTEXT_DEFAULT_FUNCTION,
  CONTEXT_DEFAULT_OBJ,
} from '../../../constants/defaults';
import type { BaseCheckoutItem } from '../../checkout/types';
import type { CheckoutReceiptItem } from '../types';
import type { CheckoutReceiptItemStateProps } from '../types/state';
import { deserializeBlockverseObjId } from '~src/shared-apps/blockverse/utils/serialize';
type CheckoutReceiptContextType = {
  receiptItems: CheckoutReceiptItem[] | undefined | null;
  transactionStatus: QueryStatus | undefined | null;
  transactionReceiptRes: UseWaitForTransactionReceiptReturnType<
    Config,
    AvailableChainId<'evm'>
  >;
  txnHash: Evm.TxnHash;
  handleRemoveMintedItemsFromCart: () => void;
  removableItemsFromCart: CartItem[] | undefined;
};

export const CheckoutReceiptContext = createContext<CheckoutReceiptContextType>(
  {
    receiptItems: undefined,
    transactionStatus: undefined,
    txnHash: NULL_BYTES as Evm.TxnHash,
    transactionReceiptRes: CONTEXT_DEFAULT_OBJ,
    handleRemoveMintedItemsFromCart: CONTEXT_DEFAULT_FUNCTION,
    removableItemsFromCart: undefined,
  },
);

export type CheckoutProviderProps = {
  txnHash: Evm.TxnHash;
  shouldAutomaticallyRemoveMintedItemsFromCart?: boolean;
};

export const useCheckoutReceiptContext = () => {
  const context = useContext(CheckoutReceiptContext);
  if (!context) {
    throw new Error(
      'useCheckoutReceiptContext must be used within a CheckoutReceiptContext',
    );
  }
  return context;
};

export const CheckoutReceiptProvider = ({
  children,
  txnHash,
  shouldAutomaticallyRemoveMintedItemsFromCart = false,
}: CheckoutProviderProps & { children: React.ReactNode }) => {
  const chain = usePreferredChain();
  const transactionRes = useTransaction({
    hash: txnHash,
    chainId: chain.id,
    query: {
      retry: (failureCount: number) => {
        if (failureCount > 4) {
          return false;
        }
        return true;
      },
      retryDelay: (failureCount: number) => {
        return failureCount * 500;
      },
    },
  });

  const transactionReceiptRes = useWaitForTransactionReceipt({
    hash: txnHash,
    chainId: chain.id,
  });

  const deployment = usePreferredDeployment();

  /**
   * For  the sake of ease of shipping, we will not be monitoring the
   * live state of the receipt's to be minted items. With L2s, this is not
   * a big deal. If needed, we can deal with it later.
   */

  // const mintState = useCheckoutMintState((s) => s.mintState);

  const checkoutItemsStateMapFromTxn = useMemo(() => {
    if (!transactionReceiptRes.data) {
      return {};
    }
    const checkoutItemsStateMap: Record<string, CheckoutReceiptItemStateProps> =
      {};
    for (const [projectId, checkoutMetadata] of Object.entries(
      CHECKOUT_PROJECT_METADATA,
    )) {
      if (
        !isSupportedChainForProjectForCheckout(
          projectId as SupportedCheckoutProjectId,
          chain.id,
        )
      ) {
        continue;
      }
      const projectCheckoutItemsStateMap =
        checkoutMetadata.getCheckoutStatesFromEvents(
          chain.id,
          transactionReceiptRes.data.logs,
        );
      for (const [id, state] of Object.entries(projectCheckoutItemsStateMap)) {
        checkoutItemsStateMap[id] = state;
      }
    }
    return checkoutItemsStateMap;
  }, [transactionReceiptRes.data, chain.id]);

  const receiptItems = useMemo((): CheckoutReceiptItem[] | undefined | null => {
    if (!transactionRes.data) {
      return undefined;
    }
    if (!deployment) {
      return null;
    }

    const baseItems: BaseCheckoutItem[] = [];

    if (
      isEqAddress(transactionRes.data.to, deployment.utils.mintingMultiCall)
    ) {
      const { input } = transactionRes.data;
      const callArgs = decodeFunctionData({
        abi: mintingMulticallAbi,
        data: input,
      });

      if (callArgs.functionName !== 'safeAggregate3Value') {
        return null;
      }

      for (const call of callArgs.args[0]) {
        for (const checkoutProjectMetadata of Object.values(
          CHECKOUT_PROJECT_METADATA,
        )) {
          const itemsFromProject =
            checkoutProjectMetadata.getBaseCheckoutItemFromCall(
              chain.id,
              deployment,
              call,
            );
          if (!itemsFromProject) {
            continue;
          }
          baseItems.push(...itemsFromProject);
        }
      }
    }
  
    // add any missing items from the checkoutItemsStateMapFromTxn
    for (const [id,] of Object.entries(checkoutItemsStateMapFromTxn)) {
      const objId = deserializeBlockverseObjId(id);
      if (!baseItems.find((v) => serializeId(v.objId) === id)) {
        baseItems.push({ objId });
      }
    }
    // decode events
    // const transferEventArgs: TransferEventArgs[] = [];

    // if (!!transactionReceiptRes.data) {
    //   const { logs } = transactionReceiptRes.data;
    //   for (const log of logs) {
    //     try {
    //       const decodedEvent = decodeEventLog({
    //         abi: erc721CAbi,
    //         data: log.data,
    //         topics: log.topics,
    //       });
    //       if (decodedEvent?.eventName !== 'Transfer') {
    //         continue;
    //       }
    //       transferEventArgs.push(decodedEvent.args);
    //     } catch {}
    //   }
    // }

    const items: CheckoutReceiptItem[] = [];
    for (const baseItem of baseItems) {
      let stateProps: CheckoutReceiptItemStateProps = { type: 'loading' };
      // let to: string | undefined = undefined;
      // const ms = mintState[baseItem.project][chain!.id]?.[baseItem.id];
      if (transactionReceiptRes.status === 'pending') {
        stateProps = { type: 'pending' };
        // if (ms?.mintState === 'claimed') {
        //   status = 'claimed';
        // }
      }
      if (transactionReceiptRes.status === 'error') {
        stateProps = { type: 'failed' };
      }

      if (transactionReceiptRes.status === 'success') {
        stateProps = { type: 'did-not-mint' }; // set the state to default it is already mined
        // if (ms?.mintState === 'claimed') {
        //   status = 'claimed';
        const serializedObjId = serializeId(baseItem.objId);
        if (!!checkoutItemsStateMapFromTxn[serializedObjId]) {
          stateProps = checkoutItemsStateMapFromTxn[serializedObjId];
        }
      }

      items.push({
        ...baseItem,
        state: stateProps,
      });
    }
    return items;
  }, [
    deployment,
    chain,
    transactionRes.data,
    transactionReceiptRes.data,
    checkoutItemsStateMapFromTxn,
  ]);

  const queryClient = useQueryClient();
  useEffect(() => {
    if (!receiptItems) {
      return;
    }

    for (const item of receiptItems) {
      const queryKey = getTransientCheckoutItemStateQueryKey(item.objId);
      // console.log(queryKey, item.state)
      queryClient.setQueriesData(
        {
          queryKey,
        },
        item.state,
      );
    }
  }, [receiptItems]);
  // const cart = useCartItemsForChainId(undefined, true);
  const cart = useCart((s) => s.cart);

  const removableItemsFromCart = useMemo(() => {
    if (!cart) {
      return undefined;
    }
    if (!receiptItems) {
      return undefined;
    }
    const mintedReceiptItems = receiptItems.filter(
      (v) => v.state.type === 'minted',
    );
    const removableItem: CartItem[] = [];
    for (const item of Object.values(cart)) {
      if (find(mintedReceiptItems, (v) => v.objId === item.objId)) {
        removableItem.push(item);
      }
    }
    return removableItem;
  }, [cart, receiptItems]);

  const removeItemsFromCart = useCart((s) => s.removeItemsFromCart);

  const handleRemoveMintedItemsFromCart = useCallback(() => {
    if (!removableItemsFromCart) {
      return;
    }
    removeItemsFromCart(removableItemsFromCart.map((i) => i.objId));
  }, [removableItemsFromCart]);

  const context = useMemo(() => {
    if (!receiptItems) {
      return undefined;
    }

    return {
      type: 'checkout' as const,
      mintQuantity: receiptItems.length,
      txnHash,
      chainId: chain.id,
      description: 'Checkout Receipt',
      source: null,
      temporal: {
        dateUpdated: Date.now(),
        ttl: null,
      },
    };
  }, [receiptItems]);

  useAddSingleBlockverseContext(
    useHiraethObjId(chain.id as SupportedBlockverseChainId<'hiraeth'>, txnHash),
    context,
  );
  // }, [updatedContext, events, receiptItems]);

  const state = useMemo(() => {
    return {
      receiptItems,
      transactionStatus: transactionReceiptRes.status,
      txnHash,
      transactionReceiptRes,
      removableItemsFromCart,
      handleRemoveMintedItemsFromCart,
    };
  }, [
    receiptItems,
    txnHash,
    transactionReceiptRes,
    handleRemoveMintedItemsFromCart,
    // removableItemsFromCart,
  ]);

  return (
    <CheckoutReceiptContext.Provider value={state}>
      {children}
    </CheckoutReceiptContext.Provider>
  );
};
