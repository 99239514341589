import type { FC } from 'react';
import { CheckoutReceiptDialog } from '~src/shared-apps/receipt/modals/CheckoutReceiptDialog';
import { CheckoutSettlementDialog } from './CheckoutSettlementDialog';

export const CheckoutDialogs: FC = () => {
  return (
    <>
      <CheckoutSettlementDialog />
      <CheckoutReceiptDialog />
    </>
  );
};
