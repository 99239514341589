import type { FC } from 'react';
import { BaseCheckoutStateCard } from '~src/shared-apps/checkout/components/stateCard';
import { Box } from '~src/styled-system/jsx';
import { useCheckoutReceiptContext } from '../../providers/CheckoutReceipt';
import { TxnDetailsCard } from '../txn';

export const CheckoutReceiptFooter = () => {
  const { transactionStatus } = useCheckoutReceiptContext();

  return (
    <Box display={'grid'} gap={'innerBox'}>
      <TxnDetailsCard />
      {transactionStatus === 'error' && <FailedCard />}
      {transactionStatus === 'success' && <RemoveMintedItemsCard />}
    </Box>
  );
};

const FailedCard: FC = () => {
  return (
    <BaseCheckoutStateCard
      title="Something went wrong while minting."
      description={`Reach out to Pob Studio to help resolve the issue. Please provide this receipt when doing so.`}
      source={'global'}
      state={'error'}
    />
  );
};

const RemoveMintedItemsCard: FC = () => {
  const { handleRemoveMintedItemsFromCart, removableItemsFromCart } =
    useCheckoutReceiptContext();

  const isItemsRemovable = !!removableItemsFromCart?.length;

  if (!isItemsRemovable) return null;

  return (
    <BaseCheckoutStateCard
      title="Remove minted items from your cart"
      description={`Automatically clean up your cart of any minted items.`}
      source={'global'}
      state={'neutral'}
      action={handleRemoveMintedItemsFromCart}
      actionLabel={'Remove Items'}
    />
  );
};
