import { ZERO } from '@pob/shared';
import { useMemo, type FC, type ReactNode } from 'react';
import { useEstimateFeesPerGas } from 'wagmi';
import { SPANS_BY_CHAIN_ID } from '~src/components/chains/spans';
import {
  HighlightSpan,
  Strong,
  SuperStrong,
  WeaklyLightenSpan,
} from '~src/components/text/spans';
import { useMounted } from '~src/hooks/ui/useMounted';
import { usePreferredChain } from '~src/providers/PreferredNetwork';
import { styled } from '~src/styled-system/jsx';
import { prettifyTokenValue } from '~src/utils/number/prettify';
import { useCheckoutContext } from '../providers/Checkout';
import { CheckoutPriceUnit } from './item/common';

export const CheckoutPricingDetails: FC = () => {
  return (
    <CheckoutDetailsContainer>
      <CheckoutSubtotalLineItem />
      <CheckoutGasFeeLineItem />
      <CheckoutTotalPriceLineItem />
    </CheckoutDetailsContainer>
  );
};

const CheckoutSubtotalLineItem: FC = () => {
  const { price } = useCheckoutContext();
  return (
    <CheckoutLineItem
      label="Subtotal"
      value={
        <>
          {prettifyTokenValue(price?.totalPrice, 4)}{' '}
          <CheckoutPriceUnit>{price?.currency}</CheckoutPriceUnit>
        </>
      }
    />
  );
};

const CheckoutGasFeeLineItem: FC = () => {
  const isMounted = useMounted();
  const { mintGasLimit } = useCheckoutContext();
  const chain = usePreferredChain();
  const { data } = useEstimateFeesPerGas({
    chainId: chain?.id,
    query: {
      enabled: !!chain,
    },
  });

  const bestGasFeeRate = useMemo(() => {
    if (!data) return undefined;
    return data.gasPrice ?? data.maxFeePerGas;
  }, [data]);

  return (
    <CheckoutLineItem
      label={<>Gas Fee</>}
      value={
        <>
          <WeaklyLightenSpan fontSize={'p.display.caption'}>
            {!!chain ? SPANS_BY_CHAIN_ID[chain?.id] : null}
          </WeaklyLightenSpan>{' '}
          &nbsp;
          <HighlightSpan fontSize={'p.display.caption'}>
            <SuperStrong>
              {prettifyTokenValue(
                !isMounted ? undefined : bestGasFeeRate,
                0,
                9,
              )}
            </SuperStrong>
            &nbsp;
            <WeaklyLightenSpan>gwei</WeaklyLightenSpan>
          </HighlightSpan>
          <WeaklyLightenSpan
            px={'threeQuartersIncrement'}
            opacity={'20'}
          ></WeaklyLightenSpan>
          {prettifyTokenValue(
            !!bestGasFeeRate
              ? bestGasFeeRate * (mintGasLimit ?? ZERO)
              : undefined,
            4,
          )}{' '}
          <CheckoutPriceUnit>{chain?.nativeCurrency.symbol}</CheckoutPriceUnit>
        </>
      }
    />
  );
};

const CheckoutTotalPriceLineItem: FC = () => {
  const { price } = useCheckoutContext();
  return (
    <CheckoutDetailRow
      pt={'innerBox'}
      borderTop={'1px solid'}
      borderColor={'darken.025'}
    >
      <CheckoutDetailLabel>Total</CheckoutDetailLabel>
      <CheckoutTrackingLine />
      <CheckoutDetailValue fontSize={'h3'}>
        <>
          <Strong>{prettifyTokenValue(price?.totalPrice, 4)}</Strong>{' '}
          <CheckoutPriceUnit>{price?.currency}</CheckoutPriceUnit>
        </>
      </CheckoutDetailValue>
    </CheckoutDetailRow>
  );
};

const CheckoutLineItem: FC<{
  label: ReactNode;
  value: ReactNode;
}> = ({ label, value }) => {
  return (
    <CheckoutDetailRow>
      <CheckoutDetailLabel>{label}</CheckoutDetailLabel>
      <CheckoutTrackingLine />
      <CheckoutDetailValue>{value}</CheckoutDetailValue>
    </CheckoutDetailRow>
  );
};
const CheckoutDetailsContainer = styled('div', {
  base: {
    display: 'flex',
    flexDirection: 'column',
    gap: 'box',
    p: 'innerBox',
    bg: 'white',
    border: '1px solid',
    borderColor: 'gray.200',
    rounded: 'innerBox',
    shadow: 'soft',
  },
});

const CheckoutDetailRow = styled('div', {
  base: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 'box',
    alignItems: 'center',
  },
});

const CheckoutDetailLabel = styled('p', {
  base: {
    fontSize: 'p.display',
    lineHeight: 'singleLine',
    fontWeight: 'display.normal',
    color: 'gray.400',
  },
});

const CheckoutDetailValue = styled('p', {
  base: {
    fontSize: 'p.display',
    lineHeight: 'singleLine',
    fontWeight: 'display.bold',
  },
});

const CheckoutTrackingLine = styled('div', {
  base: {
    height: '1',
    width: '[100%]',
    // backgroundColor: 'darken.03',
    flexGrow: 1,
    flexBasis: '0',
  },
});
