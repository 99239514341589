import type { Evm } from '@pob/shared';
import { usePrivy } from '@privy-io/react-auth';
import { isAvailableChainId } from '@protocol/chains';
import { FC } from 'react';
import { match } from 'ts-pattern';
import { useAccount, useBalance, useConnect, useDisconnect } from 'wagmi';
import { injected } from 'wagmi/connectors';
import { StdButton } from '~src/components/button';
import { DeferToSwitchChainPopover } from '~src/components/chains/deferToSwitchChainPopover';
import { LineBreak } from '~src/components/decoration/lineBreak';
import { DisplayP } from '~src/components/text';
import { useBestDisplayName } from '~src/hooks/address/useBestDisplayName';
import { useIsActionBlockedByChainForWallet } from '~src/hooks/wallet/useDeferActionToUnblockWalletState';
import { usePreferredChainId } from '~src/providers/PreferredNetwork';
import { Box, Flex, HStack, VStack, styled } from '~src/styled-system/jsx';
import { prettifyTokenValue } from '~src/utils/number/prettify';
import { useCheckoutContext } from '../providers/Checkout';
import type { CheckoutErrorType } from '../types/errors';
import { CheckoutError } from './errors';
import { logula } from '~src/utils/debug/consola';

export const CheckoutActions: FC = () => {
  const { checkoutContext } = useCheckoutContext();
  return (
    <CheckoutActionsContainer>
      <CheckoutActionErrors />
      <Flex gap={'innerBox'} justify={'space-between'}>
        <Box />
        <HStack gap={'innerBox'}>
          <CheckoutActorInfo />
          {match(checkoutContext)
            .with('mini-app', () => <CheckoutAction />)
            .with('dapp', () => <CheckoutAction />)
            .exhaustive()}
        </HStack>
      </Flex>
    </CheckoutActionsContainer>
  );
};

const CheckoutActionErrors: FC = () => {
  const { errors } = useCheckoutContext();
  const errorEntries = Object.entries(errors);
  return (
    <>
      <VStack alignItems={'stretch'} gap={'innerBox'}>
        {errorEntries.map(([key, e]) => (
          <CheckoutError
            key={`checkout-error-${key}`}
            error={{
              ...e,
              type: key as CheckoutErrorType,
            }}
          />
        ))}
      </VStack>
      {errorEntries.length > 0 ? (
        <LineBreak
          bg={'gray.100'}
          mx={'-innerBox'}
          width={'[calc(100% + 2*{padding.innerBox})]'}
          my={'innerBox'}
        />
      ) : null}
    </>
  );
};

const CheckoutAction: FC = () => {
  const { mint, mode, isMintDisabled } = useCheckoutContext();
  // const { openConnectModal } = useConnectModal();
  const { login, ready, logout, user } = usePrivy();
  const { address } = useAccount();
  const chainId = usePreferredChainId();
  const isBlocked = useIsActionBlockedByChainForWallet(chainId);
  console.log('user', user, address);

  const { connect } = useConnect();
  const { disconnect } = useDisconnect();
  const { checkoutContext } = useCheckoutContext();

  if (mode === 'in-time' && !address) {
    return (
      <StdButton
        btnType={'secondary'}
        onClick={() => {
          if (user && checkoutContext === 'mini-app') {
            // HACKY: we need to logout if the user is logged in and the checkout context is mini-app
            logula.withTag('mini-app').info('logging out privy user (HACK)');
            logout();
          } else if (checkoutContext === 'mini-app') {
            // we will bypass privy and connect with the mini-app provider
            logula.withTag('mini-app').info('connecting with mini-app');
            connect({
              connector: injected({
                target: () => {
                  return {
                    id: 'up-provider-mini-app',
                    name: 'UP Provider',
                    provider(window) {
                      return (window as any).lukso as any;
                    },
                  };
                },
              }),
            });
          } else {
            login();
          }
        }}
        disabled={!ready}
        rounded={'squaredButton'}
      >
        Connect Wallet
      </StdButton>
    );
  }

  if (isBlocked) {
    return (
      <DeferToSwitchChainPopover requiredChain={chainId}>
        <StdButton btnType={'secondary'} rounded={'squaredButton'}>
          Mint
        </StdButton>
      </DeferToSwitchChainPopover>
    );
  }

  return (
    <StdButton
      btnType={'secondary'}
      disabled={isMintDisabled}
      onClick={mint}
      rounded={'squaredButton'}
    >
      Mint
    </StdButton>
  );
};

const CheckoutActorInfo: FC = () => {
  const { chainId, chain, address } = useAccount();
  const bestName = useBestDisplayName(address as Evm.Address);
  const { data: balance } = useBalance({ address });

  if (!chainId || !isAvailableChainId(chainId)) return null;
  if (!address) return null;

  return (
    <VStack
      alignSelf={'stretch'}
      borderRight={'1px solid'}
      borderColor={'gray.100'}
      pr={'increment'}
      my={'halfIncrement'}
      alignItems={'flex-end'}
      gap={'quarterIncrement'}
      justifyContent={'center'}
    >
      {/* <Circle size={'icon.eighteen'} bg={'gray.100'}
      pos={'relative'} overflow={'hidden'}>
      {ICONS_BY_CHAIN_NETWORKS[chainId]}
      </Circle> */}
      <DisplayP.Caption fontWeight={'display.bold'} color={'gray.600'}>
        {bestName ?? '-'}
      </DisplayP.Caption>
      <DisplayP.Caption color={'gray.400'}>
        {prettifyTokenValue(balance?.value)}{' '}
        {chain?.nativeCurrency?.symbol ?? '-'}
      </DisplayP.Caption>
    </VStack>
  );
};

const CheckoutActionsContainer = styled('div', {
  base: {
    // pos: 'sticky',
    // bottom: '0',
    // left: '0',
    // right: '0',
    // borderTop: '1px solid',
    // borderColor: 'gray.100',
    // p: 'innerBox',
  },
});
