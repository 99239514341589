import { FC, ReactNode } from 'react';
import { Flex, styled } from '~src/styled-system/jsx';

export const UnicodeLineBreak: FC<{
  children: ReactNode;
}> = ({ children }) => {
  return (
    <Flex flx={'flexRow'}>
      <LineBreak />
      <Flex flx={'flexColumnCenter'}>{children}</Flex>
      <LineBreak />
    </Flex>
  );
};

export const LineBreak = styled('div', {
  base: {
    height: 'nudge',
    width: '100%',
    flexGrow: 1,
    bg: 'gray.100',
  },
});
