import { X } from 'lucide-react';
import type { FC } from 'react';
import { SquareIconButton } from '~src/components/button';
import { Dialog } from '~src/components/dialog';
import { H4 } from '~src/components/text';
import { Flex, HStack } from '~src/styled-system/jsx';
import { useCheckoutContext } from '../providers/Checkout';

export const CheckoutHeader: FC = () => {
  const { setStateInflow } = useCheckoutContext();
  return (
    <HStack justifyContent={'space-between'} alignItems={'center'}>
      <H4
        fontWeight={'display.bold'}
        lineHeight={'singleLine'}
        color={'gray.600'}
        isMobile
      >
        Checkout
      </H4>
      <Flex gap={'increment'}>
        {/* <SquareIconButton onClick={() => setStateInflow('receipt')}>
          <TestTube />
        </SquareIconButton> */}
        <Dialog.CloseTrigger asChild>
          <SquareIconButton>
            <X />
          </SquareIconButton>
        </Dialog.CloseTrigger>
      </Flex>
    </HStack>
  );
};
