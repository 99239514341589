import type { SupportedBlockverseChainId } from '@pob/blockverse';
import Link from 'next/link';
import { FC, useCallback } from 'react';
import { SquareMinorA } from '~src/components/anchor';
import { SquareMinorButton } from '~src/components/button';
import { Dialog } from '~src/components/dialog';
import { BaseLoadingImg } from '~src/components/img';
import { Spacer } from '~src/components/layout';
import { DisplayP, H4 } from '~src/components/text';
import { useHiraethArtImageUrlByChainAndTxnHash } from '~src/pages/hiraeth/txn/hooks/useHiraethArtImageUrl';
import { usePreferredChainId } from '~src/providers/PreferredNetwork';
import { useCheckoutContext } from '~src/shared-apps/checkout/providers/Checkout';
import { css } from '~src/styled-system/css';
import { Box, HStack, VStack, styled } from '~src/styled-system/jsx';
import { getChainNetwork } from '~src/utils/chain';
import { useCheckoutReceiptContext } from '../../providers/CheckoutReceipt';
import { Em } from '~src/components/text/spans';

export const CheckoutReceiptDismiss: FC = () => {
  const { txnHash, transactionStatus } = useCheckoutReceiptContext();
  const { setModalOpen, resetCheckout } = useCheckoutContext();
  const chainId = usePreferredChainId<SupportedBlockverseChainId<'hiraeth'>>();

  const artUrl = useHiraethArtImageUrlByChainAndTxnHash(chainId, txnHash);

  const handleDismiss = useCallback(() => {
    setModalOpen(false);
    resetCheckout();
  }, [setModalOpen, resetCheckout]);

  if (transactionStatus !== 'success') {
    return (
      <Box
        border={'1px solid'}
        borderColor={'gray.100'}
        rounded={'innerBox'}
        p={'innerBox'}
      >
        <HStack justify={'flex-end'}>
          <Dialog.CloseTrigger asChild>
            <SquareMinorButton btnType={'quaternarySolid'} color={'gray.600'}>
              Dismiss
            </SquareMinorButton>
          </Dialog.CloseTrigger>
        </HStack>
      </Box>
    );
  }

  return (
    <Box
      border={'1px solid'}
      borderColor={'gray.100'}
      rounded={'innerBox'}
      p={'innerBox'}
    >
      <HiraethArtPreviewContainer>
        {!!artUrl && (
          <BaseLoadingImg
            src={artUrl}
            fill
            alt={`${txnHash} art`}
            unoptimized
            className={css({
              imageRendering: 'pixelated',
              objectPosition: 'center',
              opacity: '40',
            })}
          />
        )}
      </HiraethArtPreviewContainer>
      <Spacer.InnerBox />
      <VStack flx={'flexColumnCenter'} gap={'increment'}>
        {/* <GlobeSearchIcon className={css({
      color: 'gray.600',
      width: 'icon.twentyFour',
      height: 'icon.twentyFour',
      display: 'block', 
    })}/> */}
        <DisplayP.Default color={'gray.600'} fontWeight={'display.normal'} lineHeight={'singleLine'} textAlign={'center'}>
          View your minting transaction as a <Em>token</Em>
        </DisplayP.Default>
      </VStack>
      <Spacer.Box />
      <HStack justify={'center'} gap={'increment'}>
        <Link
          passHref
          href={{
            pathname: '/hiraeth/[chainNetwork]/[txnHash]',
            query: {
              chainNetwork: getChainNetwork(chainId),
              txnHash,
            },
          }}
          legacyBehavior
        >
          <SquareMinorA color={'gray.700'} onClick={handleDismiss} btnType={'quaternarySolid'}>
            View
          </SquareMinorA>
        </Link>
        <SquareMinorButton
          onClick={handleDismiss}
          btnType={'quaternary'}
          color={'gray.600'}
        >
          Dismiss
        </SquareMinorButton>
      </HStack>
    </Box>
  );
};

const HiraethArtPreviewContainer = styled('div', {
  base: {
    maxWidth: '[20rem]',
    height: '[4rem]',
    m: 'auto',
    pos: 'relative',
    roundedBottom: 'box',
    overflow: 'hidden',
    shadow: 'soft',
    mt: '-innerBox',
  },
});
